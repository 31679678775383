import * as React from "react"


import Layout from "../components/layout"
import Seo from "../components/seo"
import VirtualTours from "../components/pages/VirtualTours"

import { useIntl } from "gatsby-plugin-intl-v4"

const VirtualToursPage = () => {

  const intl = useIntl()

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: 'h1.virtualTours' })}
        description={intl.formatMessage({ id: 'seo.description' })}
        pageName={'virtual-tours'}
      />
      <VirtualTours />
    </Layout>
  )
}

export default VirtualToursPage
