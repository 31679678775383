// extracted by mini-css-extract-plugin
export var bufferUp = "virtualToursAll-module--bufferUp--PFGvS";
export var buttonBall = "virtualToursAll-module--buttonBall--dkmmc";
export var buttonLinkConstruction = "virtualToursAll-module--buttonLinkConstruction--wjpks";
export var buttonLinkSep = "virtualToursAll-module--buttonLinkSep--Qdttf";
export var buttons = "virtualToursAll-module--buttons--89wMi";
export var closeModal = "virtualToursAll-module--closeModal--U+SCH";
export var cross = "virtualToursAll-module--cross--K3o-N";
export var hide = "virtualToursAll-module--hide--JxkIz";
export var iframeContainer = "virtualToursAll-module--iframeContainer--4G1+N";
export var individual = "virtualToursAll-module--individual--JO39q";
export var modalConstruction = "virtualToursAll-module--modalConstruction--Jqd3r";
export var row = "virtualToursAll-module--row--nry+n";
export var section = "virtualToursAll-module--section--eB4w+";