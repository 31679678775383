import * as React from "react"

import * as styles from "./VirtualTours.module.css"
import Header from "../header"
import Footer from "../footer"

import { useIntl } from "gatsby-plugin-intl-v4"

import { StaticImage } from "gatsby-plugin-image"

import Occupancy2024SVG from '../../assets/svg/txt_occupancy2024.svg'
import Occupation2024SVG from '../../assets/svg/txt_occupation2024.svg'
import VirtualToursAll from "../virtualToursAll"

const VirtualTours = () => {

    const intl = useIntl();

    return (
        <div className={`pageContainer`}>
            <Header hidePlaceholder={true} />
            <VirtualToursAll />
            <section className="footerSection fp-auto-height">
                <div className={styles.sectionLastImage}>
                    <Occupancy2024SVG width="100%" className={`${styles.titleHalfScreen} ${intl.locale === 'en' ? "" : styles.hide}`} />
                    <Occupation2024SVG width="100%" className={`${styles.titleHalfScreen} ${intl.locale === 'fr' ? "" : styles.hide}`} />
                    <StaticImage
                        src='../../images/homepage/Mansfield_016_L.jpg'
                        className={styles.footerImg}
                        loading="eager"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={90}
                        formats={["auto", "webp", "avif"]}
                        alt={intl.formatMessage({ id: 'alts.livingRoom' })}
                        style={{
                            maxHeight: "50vh"
                        }}
                    />
                </div>
                <Footer hideBorder={true} />
            </section>
        </div>
    )
}

export default VirtualTours
