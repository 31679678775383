import * as React from "react"
import { Link, useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./virtualToursAll.module.css"
import { StaticImage } from "gatsby-plugin-image";
import VirtualToursOne from "./virtualToursOne";

import ButtonV2 from "./buttonV2"

const VirtualToursAll = () => {

  const intl = useIntl();
  const [selected, setSelected] = React.useState(null);
  const [showConstructionModal, setShowConstructionModal] = React.useState(false);

  return (
    <>
      <section className={`${styles.section}`}>
        <div className={styles.bufferUp}></div>
        <div className={`row ${styles.row}`}>
          <div className={`col-xl-6 ${styles.individual}`}>
            <VirtualToursOne
              title={1707}
              floor={17}
              image={<StaticImage
                src='../images/virtualTours/1707_2022-10-21_commonground_MANSFIELD_PH_Livingroom_Cam1_Final_update_HR.jpg'
                loading="eager"
                placeholder="dominantColor"
                layout="constrained"
                quality={90}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.preview' }) + " 1707"}
                style={{
                  height: "100%",
                  maxWidth: "100%"
                }}
              />}
              bedrooms={3}
              bathrooms={2}
              surface={"2 091"}
              url={"https://storage.net-fs.com/hosting/6278801/20/"}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div className={`col-xl-6 ${styles.individual}`}>
            <VirtualToursOne
              title={1509}
              floor={15}
              image={<StaticImage
                src='../images/virtualTours/1509_cam002.0000.jpg'
                loading="eager"
                placeholder="dominantColor"
                layout="constrained"
                quality={90}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.preview' }) + " 1509"}
                style={{
                  height: "100%",
                  maxWidth: "100%"
                }}
              />}
              bedrooms={2}
              bathrooms={2}
              surface={"1 187"}
              url={"https://dev.gmv3d.ca/reflectiveworks/1509/"}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div className={`col-xl-6 ${styles.individual}`}>
            <VirtualToursOne
              title={1803}
              floor={18}
              image={<StaticImage
                src='../images/virtualTours/1803_cam01_review.jpg'
                loading="eager"
                placeholder="dominantColor"
                layout="constrained"
                quality={90}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.preview' }) + " 1803"}
                style={{
                  height: "100%",
                  maxWidth: "100%"
                }}
              />}
              bedrooms={3}
              bathrooms={2}
              surface={"1 168"}
              url={"https://dev.gmv3d.ca/reflectiveworks/1803/"}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div className={`col-xl-6 ${styles.individual}`}>
            <VirtualToursOne
              title={1809}
              floor={18}
              image={<StaticImage
                src='../images/virtualTours/1809_cam001.0000.jpg'
                loading="eager"
                placeholder="dominantColor"
                layout="constrained"
                quality={90}
                formats={["auto", "webp", "avif"]}
                alt={intl.formatMessage({ id: 'alts.preview' }) + " 1809"}
                style={{
                  height: "100%",
                  maxWidth: "100%"
                }}
              />}
              bedrooms={3}
              bathrooms={2}
              surface={"1 600"}
              url={"https://dev.gmv3d.ca/reflectiveworks/1809/"}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <div>
            <Link to="/plans/" style={{textDecoration: "none"}}>
              <ButtonV2
              type="link"
                content={intl.formatMessage({ id: 'homepage.textWithImagesInline.seePlan' })}
              />
            </Link>
          </div>
          <div className={styles.buttonLinkSep}>
            {intl.formatMessage({ id: 'homepage.textWithImagesInline.or' })}
          </div>
          <div className={styles.buttonLinkConstruction}>
            <ButtonV2 content={
              <span>
                <span className={styles.buttonBall}></span>
                <span>{intl.formatMessage({ id: 'homepage.constructionButton' })}</span>
              </span>
            }
              onClick={() => setShowConstructionModal(true)}
            />
          </div>
        </div>
      </section>
      <div className={`${styles.modalConstruction} ${showConstructionModal === true ? "" : styles.hide}`}>
        <div className={styles.iframeContainer}>
          <iframe
            title="Live construction | Construction en direct"
            src="https://www.devisubox.com/dv/dv.php5?pgl=Project/interface&sRef=BUF3LSLLR"
            allowfullscreen
            style={{ border: "0", width: "748px", height: "500px", overflow: "hidden" }}
          >
          </iframe>
          <div className={styles.closeModal} role='button' tabIndex={0} onKeyDown={() => setShowConstructionModal(false)} onClick={() => setShowConstructionModal(false)}>
            <StaticImage
              className={styles.cross}
              src='../images/common/white-cross.png'
              loading="eager"
              layout="constrained"
              quality={100}
              height={25}
              formats={["auto", "webp", "avif"]}
              alt={intl.formatMessage({ id: 'alts.closeCross' })}
              placeholder="none"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default VirtualToursAll