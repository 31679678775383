import * as React from "react"
import { useIntl } from "gatsby-plugin-intl-v4"

import * as styles from "./virtualToursOne.module.css"
import ButtonV2 from "./buttonV2"

const VirtualToursOne = ({
  image,
  title,
  floor,
  bedrooms,
  bathrooms,
  surface,
  url
}) => {

  const intl = useIntl();

  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>
        <h4>{intl.formatMessage({ id: 'virtualTours.unit' })} {title}</h4>
      </div>
      <div className={styles.floorDiv}>
        <h6>{floor}{intl.formatMessage({ id: 'virtualTours.th' })} {intl.formatMessage({ id: 'virtualTours.floor' })}</h6>
      </div>
      <div className={styles.imageDiv}>
        {image}
        <div className={styles.button}>
          <ButtonV2
            content={intl.formatMessage({ id: 'virtualTours.visit' })}
            style={{ paddingLeft: "2.5vw", paddingRight: "2.5vw" }}
            onClick={() => window.location.href = url}
          />
        </div>
      </div>
      <div className={styles.statsDiv}>
        <div className={styles.leftCol}>
          <p>{intl.formatMessage({ id: 'virtualTours.bedroom' })}</p>
          <p>{intl.formatMessage({ id: 'virtualTours.bathroom' })}</p>
          <p>{intl.formatMessage({ id: 'virtualTours.surface' })}</p>
        </div>
        <div className={styles.rightCol}>
          <p>{bedrooms}</p>
          <p>{bathrooms}</p>
          <p>{surface} pi2</p>
        </div>
      </div>
    </div>
  )
}

export default VirtualToursOne